import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBFGpQUvY9LDjvEIPz3Q2Xy-byQAsftNXM",
    authDomain: "dibepal-1ba76.firebaseapp.com",
    projectId: "dibepal-1ba76",
    storageBucket: "dibepal-1ba76.appspot.com",
    messagingSenderId: "239545210131",
    appId: "1:239545210131:web:db317acf994702fca836e6"
};

// Inicialize o Firebase
const app = initializeApp(firebaseConfig);

// Inicialize o Firestore
const db = getFirestore(app);

// (Opcional) Inicialize o Auth
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
