// src/pages/CategoriesPage.js
import React, { useEffect, useState } from 'react';
import {
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from '../services/categoryService';

function CategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const data = await getCategories();
    setCategories(data);
  };

  const handleAddCategory = async () => {
    if (categoryName.trim()) {
      await addCategory({ nome: categoryName });
      setCategoryName('');
      fetchCategories();
    }
  };

  const handleUpdateCategory = async () => {
    if (editingCategory && categoryName.trim()) {
      await updateCategory(editingCategory.id, { nome: categoryName });
      setEditingCategory(null);
      setCategoryName('');
      fetchCategories();
    }
  };

  const handleEditClick = (category) => {
    setEditingCategory(category);
    setCategoryName(category.nome);
  };

  const handleDeleteClick = async (id) => {
    await deleteCategory(id);
    fetchCategories();
  };

  return (
    <div>
      <h1>Gerenciar Categorias</h1>
      <div>
        <input
          type="text"
          placeholder="Nome da Categoria"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        {editingCategory ? (
          <button onClick={handleUpdateCategory}>Atualizar Categoria</button>
        ) : (
          <button onClick={handleAddCategory}>Adicionar Categoria</button>
        )}
      </div>
      <ul>
        {categories.map((category) => (
          <li key={category.id}>
            {category.nome}
            <button onClick={() => handleEditClick(category)}>Editar</button>
            <button onClick={() => handleDeleteClick(category.id)}>
              Excluir
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoriesPage;
