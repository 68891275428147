import React, { useEffect, useState } from 'react';
import { getCategories } from '../services/categoryService';
import { getProductsByCategory } from '../services/productService';
import '../styles/HomePageStyle.css';

function HomePage({ }) {
    return (
        <div className="home-page">
            <h1>HOME</h1>
        </div>
    );
}

export default HomePage;