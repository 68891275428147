import React, { useEffect, useState } from 'react';
import {
  getProductsByCategory,
  addProduct,
  updateProduct,
  deleteProduct,
} from '../services/productService';
import { getCategories } from '../services/categoryService';
import { storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

function ProductsPage() {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState('');
  const [productCodigo, setProductCodigo] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchProducts(selectedCategoryId);
    } else {
      setProducts([]);
    }
  }, [selectedCategoryId]);

  const fetchCategories = async () => {
    const data = await getCategories();
    setCategories(data);
  };

  const fetchProducts = async (categoryId) => {
    const data = await getProductsByCategory(categoryId);
    setProducts(data);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProductImage(e.target.files[0]);
    }
  };

  const handleAddProduct = async () => {
    if (productName.trim() && selectedCategoryId && productImage) {
      try {
        const uniqueImageName = `${new Date().getTime()}_${productImage.name}`;
        const imageRef = ref(storage, `produtos/${uniqueImageName}`);

        // Upload da imagem para o Firebase Storage
        await uploadBytes(imageRef, productImage);
        const imageUrl = await getDownloadURL(imageRef);

        // Adiciona o produto ao Firestore com a URL da imagem
        await addProduct(selectedCategoryId, {
          nome: productName,
          codigo: productCodigo,
          imagem: imageUrl,
        });

        setProductName('');
        setProductCodigo('');
        setProductImage(null);
        fetchProducts(selectedCategoryId);
      } catch (error) {
        console.error('Erro ao adicionar produto:', error);
        alert('Erro ao adicionar o produto.');
      }
    } else {
      alert('Por favor, preencha todos os campos.');
    }
  };

  const handleUpdateProduct = async () => {
    if (editingProduct && productName.trim() && selectedCategoryId) {
      try {
        let imageUrl = editingProduct.imagem;

        // Se houver uma nova imagem selecionada, faça o upload e exclua a antiga
        if (productImage) {
          // Tenta excluir a imagem anterior
          if (editingProduct.imagem) {
            const oldImagePath = decodeURIComponent(editingProduct.imagem.split('/o/')[1].split('?')[0]);
            const oldImageRef = ref(storage, oldImagePath);
            await deleteObject(oldImageRef).catch((err) => {
              console.warn('Erro ao excluir a imagem anterior:', err);
            });
          }

          // Faz o upload da nova imagem
          const uniqueImageName = `${new Date().getTime()}_${productImage.name}`;
          const imageRef = ref(storage, `produtos/${uniqueImageName}`);
          await uploadBytes(imageRef, productImage);
          imageUrl = await getDownloadURL(imageRef);
        }

        // Atualiza o produto no Firestore
        await updateProduct(selectedCategoryId, editingProduct.id, {
          nome: productName,
          codigo: productCodigo,
          imagem: imageUrl,
        });

        setEditingProduct(null);
        setProductName('');
        setProductCodigo('');
        setProductImage(null);
        fetchProducts(selectedCategoryId);
      } catch (error) {
        console.error('Erro ao atualizar produto:', error);
        alert('Erro ao atualizar o produto.', error);
      }
    } else {
      alert('Por favor, preencha todos os campos.');
    }
  };

  const handleEditClick = (product) => {
    setEditingProduct(product);
    setProductName(product.nome);
    setProductCodigo(product.codigo);
    setProductImage(null); // Limpa a imagem atual para permitir uma nova seleção
  };

  const handleDeleteClick = async (id) => {
    const productToDelete = products.find((product) => product.id === id);
    if (productToDelete) {
      try {
        // Exclui a imagem associada ao produto
        const oldImagePath = decodeURIComponent(productToDelete.imagem.split('/o/')[1].split('?')[0]);
        const imageRef = ref(storage, oldImagePath);
        await deleteObject(imageRef);
      } catch (error) {
        console.warn('Erro ao excluir a imagem do produto:', error);
      }
    }

    // Exclui o produto no Firestore
    await deleteProduct(selectedCategoryId, id);
    fetchProducts(selectedCategoryId);
  };

  return (
    <div>
      <h1>Gerenciar Produtos</h1>
      <div>
        <label>Categoria:</label>
        <select
          value={selectedCategoryId}
          onChange={(e) => setSelectedCategoryId(e.target.value)}
        >
          <option value="">Selecione uma Categoria</option>
          {categories.map((category) => (
            <option value={category.id} key={category.id}>
              {category.nome}
            </option>
          ))}
        </select>
      </div>
      {selectedCategoryId && (
        <>
          <div>
            <input
              type="text"
              placeholder="Nome do Produto"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Codigo:"
              value={productCodigo}
              onChange={(e) => setProductCodigo(e.target.value)}
            />
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {editingProduct ? (
              <button onClick={handleUpdateProduct}>Atualizar Produto</button>
            ) : (
              <button onClick={handleAddProduct}>Adicionar Produto</button>
            )}
          </div>
          <ul>
            {products.map((product) => (
              <li key={product.id}>
                <img
                  src={product.imagem}
                  alt={product.nome}
                  style={{ width: '50px', height: '50px' }}
                />
                <h3>{product.nome}</h3>
                <h3>Codigo CTA: {product.codigo}</h3>
                
                <button onClick={() => handleEditClick(product)}>Editar</button>
                <button onClick={() => handleDeleteClick(product.id)}>
                  Excluir
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default ProductsPage;
