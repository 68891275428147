// src/pages/ProductListPage.js
import React, { useEffect, useState } from 'react';
import { getCategories } from '../services/categoryService';
import { getProductsByCategory } from '../services/productService';
import { useNavigate } from 'react-router-dom';
import '../styles/ProductPageStyle.css';

function ProductListPage() {
    const [categories, setCategories] = useState([]);
    const [productsByCategory, setProductsByCategory] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategoriesAndProducts();
    }, []);

    const fetchCategoriesAndProducts = () => {
        return getCategories()
            .then((categoriesData) => {
                setCategories(categoriesData);

                const productsData = {};
                const categoryPromises = categoriesData.map((category) => {
                    return getProductsByCategory(category.id)
                        .then((products) => {
                            if (products.length > 0) {
                                productsData[category.id] = products;
                            }
                        });
                });

                return Promise.all(categoryPromises)
                    .then(() => {
                        setProductsByCategory(productsData);
                    });
            })
            .catch((error) => {
                console.error('Error fetching categories and products:', error);
            });
    };

    return (
        <>
            <div className="product-page">
                {categories
                    .filter((category) => productsByCategory[category.id]?.length > 0) // Remove categorias vazias
                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena alfabeticamente
                    .map((category) => (
                        <div key={category.id} className="category-container">
                            <h2 className="category-title">{category.nome}</h2>
                            <ul className="product-list">
                                {productsByCategory[category.id]?.map((product) => (
                                    <li key={product.id} className="product-item">
                                        <img
                                            src={product.imagem}
                                            alt={product.nome}
                                            className="product-image"
                                        />
                                        <div className="product-name">{product.nome}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
            </div>
        </>
    );
}

export default ProductListPage;