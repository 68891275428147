import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CategoriesPage from './pages/CategoriesPage';
import ProductsPage from './pages/ProductsPage';
import ProductListPage from './pages/ProductListPage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/homePage';
import Header from './components/Header';
import ProtectedRoute from './services/ProtectedRoute';
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "./firebaseConfig";

function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Função para verificar se o usuário é administrador
  const checkAdminStatus = async (firebaseUser) => {
    if (firebaseUser) {
      const docRef = doc(db, "users", firebaseUser.uid); // Substitua "users" pelo nome da sua coleção
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().admin === true; // Verifica o campo `admin`
      }
    }
    return false; // Retorna false se o usuário não for admin ou não estiver autenticado
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser);

      if (firebaseUser) {
        const adminStatus = await checkAdminStatus(firebaseUser);
        setIsAdmin(adminStatus);
      } else {
        setIsAdmin(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleLogoutFromApp = async () => {
    await auth.signOut();
    setUser(null);
    setIsAdmin(false);
  };

  return (
    <Router>
      <Header user={user} isAdmin={isAdmin} onLogout={handleLogoutFromApp} />
      <Routes>
        {/* Página inicial */}
        <Route path="/" element={<HomePage user={user} handleLogout={handleLogoutFromApp} />} />

        {/* Rota protegida para categorias */}
        <Route
          path="/categories"
          element={
            <ProtectedRoute user={user}>
              <CategoriesPage user={user} handleLogout={handleLogoutFromApp} />
            </ProtectedRoute>
          }
        />

        {/* Rota aberta para lista de produtos */}
        <Route
          path="/productList"
          element={<ProductListPage user={user} handleLogout={handleLogoutFromApp} />}
        />

        {/* Rota protegida para produtos */}
        <Route
          path="/products"
          element={
            <ProtectedRoute user={user}>
              <ProductsPage user={user} handleLogout={handleLogoutFromApp} />
            </ProtectedRoute>
          }
        />

        {/* Página de login */}
        <Route path="/login" element={<LoginPage setUser={setUser} />} />

        {/* Redirecionamento para páginas não existentes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
