import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import '../styles/Header.css';
import Logo from '../img/LogoArrumada2.png';

const Header = ({ user, onLogout, isAdmin }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      onLogout();
      setIsMenuOpen(false); // Fecha o menu ao deslogar
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Logo" className="logo-image" />
          </Link>
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Início</Link></li>
            <li><Link to="/productList" onClick={closeMenu}>Produtos</Link></li>
            <li><Link to="/about" onClick={closeMenu}>Sobre</Link></li>
            {isAdmin && <li><Link to="/categories" onClick={closeMenu}>Categorias</Link></li>}
            {isAdmin && <li><Link to="/products" onClick={closeMenu}>Gerenciar Produtos</Link></li>}
            {user ? (
              <li>
                <button
                  type="button"
                  className="logout-button"
                  onClick={handleLogout}>
                  Sair
                </button>
              </li>
            ) : (
              <li>
                <Link className="login-button" to="/login" onClick={closeMenu}>Login</Link>
              </li>
            )}
          </ul>
        </nav>
        {isMenuOpen && <div className="backdrop" onClick={closeMenu}></div>}
      </div>
    </header>
  );
};

export default Header;