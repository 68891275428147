// src/services/categoryService.js
import { db } from '../firebaseConfig';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';

const categoryCollection = collection(db, 'Categorias');

export const getCategories = async () => {
  const snapshot = await getDocs(categoryCollection);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addCategory = async (category) => {
  await addDoc(categoryCollection, category);
};

export const updateCategory = async (id, category) => {
  const categoryDoc = doc(db, 'Categorias', id);
  await updateDoc(categoryDoc, category);
};

export const deleteCategory = async (id) => {
  const categoryDoc = doc(db, 'Categorias', id);
  await deleteDoc(categoryDoc);
};
