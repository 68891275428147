// src/services/productService.js
import { db } from '../firebaseConfig';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';

export const getProductsByCategory = async (categoryId) => {
  const productsCollection = collection(
    db,
    `Categorias/${categoryId}/Produto`
  );
  const snapshot = await getDocs(productsCollection);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addProduct = async (categoryId, product) => {
  const productsCollection = collection(
    db,
    `Categorias/${categoryId}/Produto`
  );
  await addDoc(productsCollection, product);
};

export const updateProduct = async (categoryId, id, product) => {
  const productDoc = doc(db, `Categorias/${categoryId}/Produto`, id);
  await updateDoc(productDoc, product);
};

export const deleteProduct = async (categoryId, id) => {
  const productDoc = doc(db, `Categorias/${categoryId}/Produto`, id);
  await deleteDoc(productDoc);
};
